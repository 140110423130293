import * as React from "react"
import JotformEmbed from 'react-jotform-embed'
import styled from "styled-components"

import Layout from "../components/layout/layout"
import Seo from "../components/seo"

const StyledH2 = styled.h2`
  font-weight: 600;
  font-size: 30px;

  @media (max-width: 768px) {
    font-size: 19px;
  }
`

const ContactPage = () => (
  <Layout>
    <Seo title="Contact Us" />

    <div className="bg-white w-full 2xl:max-w-screen-xl mx-auto">
      <div className="w-full mx-auto pb-5 px-6 xl:px-20">
        <iframe 
          src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d6605.799275441706!2d-117.802143!3d34.123319!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80c32f68d575377d%3A0x1f5b475a7b2e9dcb!2s302%20E%20Foothill%20Blvd%2C%20San%20Dimas%2C%20CA%2091773!5e0!3m2!1sen!2sus!4v1700312290274!5m2!1sen!2sus" 
          width="100%" 
          height="300" 
          title="Google Maps"
          allowfullscreen="" 
          loading="lazy" 
          referrerpolicy="no-referrer-when-downgrade">
        </iframe>
      </div>

      <div className="w-full flex flex-col md:flex-row items-start mx-auto pt-6 pb-16 px-6 xl:px-20 space-x-0 xl:space-x-14">
        <div className="w-full md:w-8/12 mx-auto">
          <StyledH2 className="text-site-green mb-3 uppercase">Contact Information</StyledH2>

          <p className="font-body text-base mb-3">
            <b>Sales Office Address</b><br />
            302 East Foothill Boulevard<br />
            San Dimas, CA 91773
          </p> 

          <p className="font-body text-base mb-3">
            <b>Mailing Address</b><br />
            5724 North Willard Avenue<br />
            San Gabriel, CA 91775
          </p>
          
          <p className="font-body text-base">
            <b>Phone</b><br />
            (909) 599-3469
          </p>

          <p className="font-body text-base mb-3">
            <b>Fax</b><br />
            (909) 599-8192
          </p>

          <p className="font-body text-base mb-3">
            <b>Email address</b><br />
            brothers.nursery@verizon.net
          </p>

          <p className="font-body text-base mb-3">
            <b>Emails for Sales Department</b><br />
            roman@brothersnursery.com
          </p>
        </div>

        <div className="w-full md:w-4/12 mx-auto mt-8 md:mt-0">
          <JotformEmbed src="https://form.jotform.com/233213830211440" />
        </div>
      </div>
    </div>
  </Layout>
)

export default ContactPage
